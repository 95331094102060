<template>
	<div>
		<NewConexaoDialog :dialog="dialogNewConexao" @dialogClose="dialogNewConexaoClose" :rtg_id="rtg_id" />

		<div>
			<v-btn color="primary" class="text-none mt-1" size="small" @click="NewConexaoClick"
				:disabled="!user.usu_terminal_conexao_incluir">
				<v-icon left>mdi-plus</v-icon>Nova Conexão
			</v-btn>

			<v-data-table :headers="headers" :items="listConexoes" disable-pagination hide-default-footer height="400">
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn class="text-none" size="small" @click="remover(item)" text
						:disabled="!user.usu_terminal_conexao_excluir">
						<v-icon left>delete</v-icon>Excluir
					</v-btn>
				</template>
				<template v-slot:[`item.rtc_ultimo_acesso`]="{ item }">
				<span v-if="item.rtc_ultimo_acesso">
					{{ convertDate(item.rtc_ultimo_acesso) + " " + convertTime(item.rtc_ultimo_acesso) + " ; " +
						calcularDiferenca(item.rtc_ultimo_acesso) }}
				</span>
			</template>
			</v-data-table>
		</div>
	</div>
</template>
<script>
import NewConexaoDialog from '../NewConexaoDialog.vue';
import * as rtconexoesService from '../../../services/rtconexoesService.js'
import { mapGetters } from 'vuex'

export default {
	props: {
		listConexoes: Array,
		rtg_id: String,
	},
	components: {
		NewConexaoDialog
	}, data: () => ({
		headers: [
			{ text: 'IP', value: 'rtc_ip' },
			{ text: 'Observação', value: 'rtc_observacao' },
			{ text: 'Últ. Acesso', value: 'rtc_ultimo_acesso' },
			{ text: 'Ações', value: 'actions', align: 'right' },
		],
		dialogNewConexao: false,
	}), methods: {
		NewConexaoClick() {
			this.dialogNewConexao = true;
		}, dialogNewConexaoClose() {
			this.dialogNewConexao = false;
			this.$emit('conexoesChanged')
		}, async remover(item) {
			// eslint-disable-next-line no-console
			//console.log(item);
			try {
				await rtconexoesService.remove(item.rtc_id);
				this.$emit('conexoesChanged')
				this.$store.dispatch('showSuccess', 'Excluído com sucesso!')
			} catch (error) {
				this.$store.dispatch('showError', error)
			}
		}, calcularDiferenca(ultimo_acesso) {
			const days = this.diasDiff(ultimo_acesso);
			if (days > 0)
				return `(${days} dias)`;

			const agora = new Date();
			const ultimoAcesso = new Date(ultimo_acesso);

			// calcular a diferença em milissegundos
			const diferencaMs = agora - ultimoAcesso;
			// calcular a diferença em minutos
			const diferencaMinutos = Math.abs(Math.floor(diferencaMs / 1000 / 60));

			if (diferencaMinutos >= 60) {
				return `(${Math.floor(diferencaMinutos / 60)} horas)`;
			} else {
				return `(${diferencaMinutos} minutos)`;
			}
		}, diasDiff(date) {
			if (!date)
				return 0;

			const agora = new Date();
			const ultimoAcesso = new Date(date);

			// calcular a diferença em milissegundos
			const diferencaMs = agora - ultimoAcesso;
			// calcular a diferença em minutos
			const diferencaDias = Math.abs(Math.floor(diferencaMs / 1000 / 60 / 60 / 24));
			return diferencaDias;
		}
	}, watch: {
		dialog() {
		}
	}, computed: {
		...mapGetters(['user']),
	}
}
</script>