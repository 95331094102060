import { ApiService } from './common/serviceBase';
import { URL_CAD_REGISTRO_TERMINAL } from './common/apiUrl';

const service = new ApiService(`${URL_CAD_REGISTRO_TERMINAL}/clientes/`);

export async function clientes_nao_associados(filter) {
    const response = await service.get("clientes-nao-associados", {
        params: {
            filter: filter
        }
    });
    return response;
}

export async function desassociar_grupo_terminal(id) {
    const response = await service.patch(id + "/define-grupo-terminal", {
        rtg_id: ""
    });
    return response;
}

export async function associar_grupo_terminal(id, rtg_id) {
    const response = await service.patch(id + "/define-grupo-terminal", {
        rtg_id: rtg_id
    });
    return response;
}

// export async function getRtg_id(id) {
//     const response = await service.get(id + "/rtg_id");
//     return response;
// }