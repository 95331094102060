<template>
	<div>
		<IncluirEmpresaDialog :dialog="dialogIncluirEmpresa" @dialogClose="dialogIncluirEmpresaClose"
			:rtg_id="rtg_id" />

		<div>
			<v-btn color="primary" class="text-none mt-1" size="small" @click="incluirEmpresaClick"
				:disabled="!user.usu_terminal_empresa_incluir">
				<v-icon left>mdi-plus</v-icon>Adicionar Empresa
			</v-btn>

			<v-data-table :headers="headers" :items="listEmpresas" disable-pagination hide-default-footer height="400">
				<template v-slot:[`item.actions`]="{ item }">
					<span>
						<v-btn class="text-none" size="small" text @click="removerClick(item)"
							:disabled="!user.usu_terminal_empresa_excluir">
							<v-icon left>delete</v-icon>Excluir
						</v-btn>
					</span>
				</template>
				<template v-slot:[`item.cidade`]="{ item }">
					<span>
						{{ item.cidade }} - {{ item.uf }}
					</span>
				</template>
			</v-data-table>
		</div>
	</div>
</template>
<script>
import IncluirEmpresaDialog from '../IncluirEmpresaDialog.vue';
import * as clientesService from '../../../services/clientesService.js'
import { mapGetters } from 'vuex'

export default {
	props: {
		listEmpresas: Array,
		rtg_id: String,
		cliente: {}
	},
	components: {
		IncluirEmpresaDialog
	}, data: () => ({
		headers: [
			{ text: 'Nome', value: 'nome' },
			{ text: 'CNPJ', value: 'cnpj' },
			{ text: 'Cidade', value: 'cidade' },
			{ text: 'Ações', value: 'actions', align: 'right' },
		],
		dialogIncluirEmpresa: false,
	}), methods: {
		incluirEmpresaClick() {
			this.dialogIncluirEmpresa = true;
		}, dialogIncluirEmpresaClose() {
			this.dialogIncluirEmpresa = false;
			this.$emit('empresasChanged')
		}, async removerClick(item) {
			// eslint-disable-next-line no-console
			//console.log(item);

			// não pode remover o cnpj matriz, senão o cadastro do Grupo de Empresas se perde
			if (this.cliente.matriz) {
				if (this.cliente.matriz == item.cnpj) {
					this.$store.dispatch('showError', 'Não é possível excluir a Empresa Matriz. Esta empresa é a base do cadastro deste grupo')
					return;
				}
			}
			else {
				// não pode remover o cnpj dono do cad cliente que abriu, senão o cadastro do Grupo de Empresas se perde
				// if (this.cliente.cnpj == item.cnpj) {
				// 	this.$store.dispatch('showError', 'Empresa não pode ser excluída. Esta empresa é a base do cadastro deste grupo')
				// 	return;
				// }
			}
			try {
				await clientesService.desassociar_grupo_terminal(item.id);
				this.$emit('empresasChanged')
				this.$store.dispatch('showSuccess', 'Excluído com sucesso!')
			} catch (error) {
				this.$store.dispatch('showError', error)
			}
		}
	}, watch: {
		dialog() {
		}
	}, computed: {
		...mapGetters(['user']),
	}
}
</script>